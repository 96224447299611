
import React, { Component } from "react";
import Header from '../components/header';
import Footer from '../components/footer';
import $ from 'jquery';

export default class index extends Component {
    componentDidMount() {
        console.log('insideComponentDidMount');
        let selectElem =  $(".js-example-placeholder-single");
        
        selectElem.select2({
            placeholder: "Select Region",
            minimumResultsForSearch: Infinity
        });

        selectElem.on('select2:opening', function(event){
            $('select').val(null);
        })

        selectElem.on('select2:select', function (e) {
            window.location.href = e.target.value;
            $('.js-example-placeholder-single').val('').trigger('change');
        });
    }

    render() {
        return (
            <div className="page-wrapper">
                <Header 
                    logoURL="https://www.solosechcp.com"
                    hcpURL="https://www.solosechcp.com/?utm_source=relevate&utm_medium=landing-page&utm_campaign=efficacy-acog-guidelines&utm_term=default"
                    isiURL="https://www.solosechcp.com/#isi?utm_source=relevate&utm_medium=landing-page&utm_campaign=efficacy-acog-guidelines&utm_term=default"
                    preInfoURL="https://dailymed.nlm.nih.gov/dailymed/fda/fdaDrugXsl.cfm?setid=551e43d5-f700-4d6e-8029-026f8a8932ff&type=display"
                />
                <main>
                <h1 className="solosec-main-heading">#1 Prescribed Branded BV-Indicated Treatment<sup><span className="star">*</span></sup></h1>
                    <p className="solosec-sub-heading">*Supported by June 2020 IQVIA Data<sup>1</sup></p>
                    <div className="banner-wrapper">
                        <h2 className="banner-heading">Solosec<sup>&reg;</sup> (secnidazole)</h2>
                        <p className="banner-sub-heading">One Packet. One Dose. One Time.</p>
                        <select className="js-example-placeholder-single js-states form-control" style={{visibility: 'hidden'}}>
                            <option></option>
                            <option value="northeast">Northeast</option>
                            <option value="southeast">Southeast</option>
                        </select>
                    </div>
                    <div className="reference-wrapper">
                        <div className="container">
                            <h3 className="ref-heading">REFERENCE:</h3>
                            <ol className="ref-data-list">
                                <li>Data on File – IQVIA Monthly &amp; Weekly TRx Data in SMART, U.S. Edition – Weekly and Monthly NPA Data (Assessed as of June 2020). Prepared June 2020.</li>
                            </ol>
                            <div className="additional-ref-wrapper mb-10">
                                <h3 className="additional-ref-heading">Indication</h3>
                                <p className="additional-ref-data">
                                    SOLOSEC<sup>&reg;</sup> (secnidazole) 2 g oral granules is a 5-nitroimidazole antimicrobial agent indicated for the treatment of bacterial vaginosis in adult women.
						        </p>
                            </div>
                            <div className="additional-ref-wrapper mb-10">
                                <h3 className="additional-ref-heading">Dosage and Administration</h3>
                                <p className="additional-ref-data">
                                    SOLOSEC is a single-dose therapy for oral use. The entire contents of SOLOSEC packet should be sprinkled onto applesauce, yogurt or pudding and consumed once within 30 minutes without
                                    chewing or crunching the granules. SOLOSEC is not intended to be dissolved in any liquid.
						        </p>
                            </div>
                            <div className="additional-ref-wrapper mb-10">
                                <h3 className="additional-ref-heading">Important Safety Information</h3>
                                <ul className="additional-ref-data additional-ref-data-list">
                                    <li>SOLOSEC is contraindicated in patients with a history of hypersensitivity to secnidazole, other ingredients of the formulation, or other nitroimidazole derivatives.</li>
                                    <li>Vulvo-vaginal candidiasis may develop with SOLOSEC and require treatment with an antifungal agent.</li>
                                    <li>Potential risk of carcinogenicity is unknown and has not been studied. Carcinogenicity has been seen in rodents chronically treated with nitroimidazole derivatives, which are structurally related to secnidazole. Chronic use should be avoided.</li>
                                    <li>Breastfeeding is not recommended. Patients should discontinue breastfeeding for 96 hours after administration of SOLOSEC.</li>
                                    <li>Most common adverse reactions observed in clinical trials (incidence ≥2%) were vulvovaginal candidiasis, headache, nausea, dysgeusia, vomiting, diarrhea, abdominal pain, and vulvovaginal pruritus.</li>
                                </ul>
                            </div>
                            <p className="contact-info mb-10">To report SUSPECTED ADVERSE REACTIONS, contact Lupin Pharmaceuticals, Inc. at 1-844-SOLOSEC (1-844-765-6732) or FDA at 1-800-FDA-1088 or <a className="reporting-link" target="_blank" href="https://www.fda.gov/medwatch">www.fda.gov/medwatch.</a></p>
                            <p className="contact-info mb-10">Please ask your Lupin Women’s Healthcare Representative for full Prescribing Information, also available at <a href="https://www.solosechcp.com" target="_blank">solosechcp.com</a>.</p>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        )
    }
}
