import React from 'react'
import logo_solosec from '../assets/images/logo-solosec.svg';

export default function Header(props) {
    return (
        <header className="header">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <nav>
                            <ul className="nav-list">
                                <li className="nav-list-item"><a href={props.hcpURL} target="_blank" rel="noopener noreferrer">For Healthcare Professionals</a></li>
                                <li className="nav-list-item"><a href={props.isiURL} target="_blank" rel="noopener noreferrer">Important Safety Information</a></li>
                                <li className="nav-list-item"><a href={props.preInfoURL} target="_blank" rel="noopener noreferrer">Prescribing Information</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="logo-wrapper">
                            <a href={props.logoURL} target="_blank" rel="noopener noreferrer"> <img className="img-fluid header-logo-img" src={logo_solosec} alt="Solosec Logo" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
