import React from 'react'
import logo_lupin from '../assets/images/logo-lupin.svg';
import landing_footer_swoosh from '../assets/images/landing-footerSwoosh.png';

export default function Footer() {
    return (
		<footer>
			<div className="container">
				<div className="footer-top d-flex justify-content-center">
					<img className="img-fluid" src={landing_footer_swoosh} alt="landing-footerSwoosh" />
				</div>
				<div className="footer-bottom">
					<img className="footer-logo-img" src={logo_lupin} alt="logo-lupin" />
					<p className="copyright-text">
						&copy; 2020 Lupin Pharmaceuticals, Inc. All rights reserved.
						Solosec<sup>&reg;</sup> is a registered trademark owned by Lupin Inc.
						<br/>
						PP-SOL-0585 Last updated 9/20
					</p>
				</div>
			</div>
		</footer>
    )
}
